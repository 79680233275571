<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dati Generali" header-tag="header">
              <b-card-text>
                <div class="form-group">
                  <base-select
                    name="bank"
                    :label="getDictionary('credit_account', 'bank_account')"
                    v-model="bank_account_id"
                    :options="bank_accounts"
                    :rules="{ required: true }"
                    @select="onSelectBankAccount"
                  />
                  <base-input
                    name="iban"
                    :label="getDictionary('debtor_iban', 'bank_account')"
                    v-model="form.iban"
                    maxlength="27"
                    :rules="{ required: true }"
                  />
                  <base-input
                    name="identifier"
                    :label="getDictionary('identifier', 'bank_account')"
                    v-model="form.identifier"
                    :rules="{ required: true }"
                  />
                  <base-select
                    name="status_setup"
                    :label="getDictionary('status_setup', 'bank_account')"
                    v-model="form.status_setup"
                    :options="options"
                    :rules="{ required: true }"
                  />
                </div>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                type="button"
                variant="lisaweb"
                size="sm"
                :disabled="invalid"
                @click="handleSubmit(onSubmit)"
              >
                Salva
              </b-button>
              <!-- <b-button @click="resetForm()" variant="lisaweb" size="sm"
                >Clear</b-button
              > -->
            </div>
          </b-form>
        </validation-observer>
      </div>
      <!-- <details-card
          v-for="(item, index) in detailFieldsSepa"
          :key="index"
          :header="item.header"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
          @update="onUpdate"
        >
        </details-card>
        <b-btn
          variant="lisaweb"
          size="sm"
          @click="openEditSepaModal()"
          id="editSepa"
        >
          Modifica
        </b-btn>
        <edit-sepa-modal :item="details"> </edit-sepa-modal> -->
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import { mapGetters } from "vuex";
// import DetailsCard from "@/components/DetailsCard";
// import EditSepaModal from "@/components/modals/editSepaModal";

export default {
  mixins: [FormMixin, ShowMixin],
  name: "Sepa",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    BaseSelect,
    BaseInput,
    // DetailsCard,
    // EditSepaModal,
  },
  data() {
    return {
      isLoading: false,
      repository: "insurance_policy",
      allRepositories: [],
      details: null,
      defaultLabels: {},
      mandatoryFields: {
        insurance_policy: {},
      },
      detailFieldsSepa: {
        sepa: {
          header: "Dati Generali",
          edit: true,
          repository: "insurance_policy",
          fields: [
            {
              label: this.getDictionary("bank", "bank_account"),
              type: "text",
              value: "",
              virtual: (detail) => {
                return !Object.keys(detail).length
                  ? false
                  : detail?.bank_accounts[0]?.pivot.bank_account_id;
              },
            },
            {
              label: this.getDictionary("iban", "bank_account"),
              type: "text",
              value: "",
              virtual: (detail) => {
                return !Object.keys(detail).length
                  ? false
                  : detail?.bank_accounts[0]?.pivot.iban;
              },
            },
            {
              label: this.getDictionary("identifier", "bank_account"),
              value: "",
              virtual: (detail) => {
                return !Object.keys(detail).length
                  ? false
                  : detail?.bank_accounts[0]?.pivot.identifier;
              },
            },
            {
              label: this.getDictionary("status_setup", "bank_account"),
              type: "select",
              value: "",
              virtual: (detail) => {
                return !Object.keys(detail).length
                  ? false
                  : detail?.bank_accounts[0]?.pivot.status_setup.text;
              },
            },
          ],
        },
      },
      form: {
        iban: null,
        identifier: null,
        status_setup: null,
      },
      bank_account_id: null,
      bank_accounts: [],
      policyNumber: null,
      statusRegistry: null,
      registryUniqueCode: null,
      // referer_types: [
      //   { value: "0", text: "Codice Fiscale" },
      //   { value: "1", text: "Numero Polizza" },
      // ],
      options: [
        { value: "0", text: "Abilitato" },
        { value: "1", text: "Temporaneo" },
        { value: "2", text: "Da rivedere" },
        { value: "3", text: "Disdettata" },
        { value: "4", text: "RID revocato" },
      ],
    };
  },
  methods: {
    ...mapGetters("auth", {
      // getInsurers: "insurers",
      getBankAccounts: "bank_accounts",
    }),
    openEditSepaModal() {
      this.$bvModal.show("editSepaModal");
    },
    fetchForm() {
      this.isLoading = true;
      let queryString = `relations/byBankAccount`;
      this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          this.policyNumber = this.details.number;
          /* */
          this.fetchShowForm("registry", data.registry_id, "")
            .then((response) => {
              const data = response.data.data;
              // console.log("data", data);
              this.statusRegistry = data.status_registry.value;
              this.registryUniqueCode =
                data.status_registry.value === 0
                  ? data?.attributables?.NINO
                  : data?.attributables?.CREG;
            })
            .catch((error) => {
              console.error(error);
            });
          /* */
          this.initSepaEditForm();
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSubmit() {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(this.repository);

      let payload = {
        bank_account: {
          [this.bank_account_id]: {
            iban: this.form.iban,
            identifier: this.form.identifier,
            status_setup: this.form.status_setup,
          },
        },
      };

      Repo.pivot_store(this.resourceId, "bank_account", payload)
        .then(() => {
          this.$emit("fetch");
          this.$showSnackbar({
            preset: "success",
            text: `Informazioni SEPA aggiornate con successo.`,
          });
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    initSepaEditForm() {
      this.bank_account_id = !Object.keys(this.details).length
        ? null
        : this.details?.bank_accounts[0]?.pivot.bank_account_id;
      this.form.iban = !Object.keys(this.details).length
        ? null
        : this.details?.bank_accounts[0]?.pivot.iban;
      this.form.identifier = !Object.keys(this.details).length
        ? null
        : this.details?.bank_accounts[0]?.pivot.identifier;
      // this.form.insurance_policy_id = !Object.keys(this.details).length
      //   ? null
      //   : this.details?.bank_accounts[0]?.pivot.insurance_policy_id;
      this.form.status_setup = !Object.keys(this.details).length
        ? null
        : this.details?.bank_accounts[0]?.pivot.status_setup.value;
    },
    onSelectBankAccount(selected) {
      // console.log("selected", selected);
      // console.log("bank_accounts", this.bank_accounts);
      const matchedBankAccount = this.bank_accounts.find(
        (bank) => bank.value === selected
      );
      if (!matchedBankAccount) return;
      // console.log("matchedBankAccount", matchedBankAccount);
      const matchedRefererType = matchedBankAccount?.referer_type;
      // console.log("matchedRefererType", matchedRefererType);
      if (!matchedRefererType) return;
      this.form.identifier =
        matchedRefererType === "0"
          ? this.registryUniqueCode
          : this.policyNumber;
    },
  },
  computed: {},
  mounted() {
    this.fetchForm();
  },
  created() {
    this.bank_accounts = this.getBankAccounts();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// #editSepa {
//   margin: 20px 0 0;
// }
</style>
